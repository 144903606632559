<template>
  <div class="scroll-container" :style="{ overflow: showDetails ? 'hidden' : 'auto' }" @scroll="onScroll">
    <div class="home-page">
      <div class="sticky">
        <div class="full" style="overflow:hidden;">
          <div class="bg-imgs full" :class="{ bigger: !showDetails }">
            <img v-for="(bg, index) in backgrounds" :key="index" :src="bg" :class="{
              imgContain: isImgsContain[index] == 1,
              imgFit: isImgsContain[index] != 1,
              show: index === currBgIndex,
            }" class="full" />
            <video v-for="(src, index) in backgroundPreviews" :key="index" v-show="index === currBgIndex" :src="src"
              :class="{
                imgContain: isImgsContain[index] == 1,
                imgFit: isImgsContain[index] != 1,
              }" autoplay muted loop playsinline class="full"></video>
            <video v-for="(src, index) in backgroundVideos" :key="index" v-show="index === currBgIndex && showDetails"
              :src="src" :class="{
                imgContain: isImgsContain[index] == 1,
                imgFit: isImgsContain[index] != 1,
              }" autoplay muted loop playsinline class="full"></video>
          </div>
        </div>
        <div class="black-cover full" :class="{ indetail: showDetails }"></div>
        <div class="content-wrapper full">

          <a href="/portfolio-2023/about.html" target="_blank">
            <svg class="rect" width="100" height="52" viewBox="0 0 100 52" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="1" width="98" height="50" stroke="#F5F5F5" stroke-width="2" />
            </svg>
            <span>
              AKARI
            </span>
          </a>
f
          <div class="intro" v-show="!showDetails">
            一些可在线体验的项目<br>
            Projects to play online.
          </div>

          <transition name="fade">
            <div v-if="!showDetails" class="works-directory full">
              <ul :style="ulStyle">
                <li v-for="({ time, title, type }, index) in works" :key="index" :style="{
                  opacity: index == currBgIndex ? 1 : 0.5
                }" @mouseover="currBgIndex = index" @click="showDetailsView(index)">
                  <span class="time">{{ time }}</span>
                  {{ title }}<sup>{{ type
                    }}</sup>
                </li>
              </ul>
            </div>
          </transition>

          <!-- 当 showDetails 为 true 时显示详情内容和返回按钮 -->
          <transition name="fade">
            <div v-if="showDetails" class="details full">
              <h1>
                {{ currDetail.title }}<sup>{{ currDetail.type }}</sup>
              </h1>
              <p class="time">{{ currDetail.fullTime }}</p>
              <!-- 分工 -->
              <div class="divisions">
                <div class="left" v-if="currDetail.division.length > 1">
                  <svg width="11" height="44" viewBox="0 0 11 44" fill="none" xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none">
                    <path d="M9.75859 1C-1.74141 10.5 -0.241412 36 4.75859 43.5" stroke="white" />
                  </svg>
                </div>
                <div class="right" v-if="currDetail.division.length > 1">
                  <div v-for="(text, index) in currDetail.division" :key="index">
                    分工 {{ text }}
                  </div>
                </div>
                <div class="right" v-if="currDetail.division.length <= 1">
                  <div v-for="(text, index) in currDetail.division" :key="index">
                    个人项目 {{ text }}
                  </div>
                </div>
              </div>
              <!-- 更多 -->
              <div class="more">
                <div v-for="(text, index) in currDetail.more" :key="index">
                  {{ text }}
                </div>
              </div>
              <!-- 项目描述 -->
              <div class="desc">
                {{ currDetail.desc }}
              </div>
              <!-- 返回 -->
              <button @click="goBack">返回</button>
            </div>
          </transition>
          <transition name="fade">
            <div v-if="showDetails" class="details-r">
              <a :href="currDetail.link" target="_blank">
                {{ currDetail.visit }}
              </a>
            </div>
          </transition>


        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      works: [{
        time: '2023',
        title: 'Portfolio Web(EN)',
        type: '网站',
        fullTime: '2023/08',
        division: [
          '（设计、内容、开发）'
        ],
        more: ['技术：html+css+js'],
        desc: '这是我的个人作品集网站，展示了自己的个人作品、或自己作为主要负责人的作品。整个网站使用原生语言编写。',
        visit: '访问一下 view',
        link: '/portfolio-2023/index.html',
      }, {
        time: '2022',
        title: '川·毕业回忆收藏馆',
        type: '展览',
        fullTime: '2022/06',
        division: [
          '策划、场景、内容等····',
          '开发+动画（负责）'
        ],
        more: ['工具：cocos creator 2d'],
        desc: '这是为毕业生准备的回忆之旅，征集了毕业生们的回忆、写给毕业生们的明信片··· 是对那几年的独特的回忆，有遗憾、有圆满、有“如果”的想象、有伙伴和不舍的回忆。',
        visit: '体验一下 play',
        link: 'https://what-177679-1306379379.tcloudbaseapp.com/chuan_museum/index.html',
      }, {
        time: '2021',
        title: '赛制介绍创意视频',
        type: '视频',
        fullTime: '2021/11',
        division: [
          '创意、道具、剪辑（负责）',
          '道具',
          '摄影',
          '配音'
        ],
        more: [],
        desc: '这是校园歌手大赛决赛赛制的介绍视频，赛制规则被制成了手帐，通过互动的方式进行赛制规则的展现。',
        visit: '看一下 watch',
        link: 'https://www.bilibili.com/video/BV1AZ4y197xt/'
      }, {
        time: '2021',
        title: '申城红色之旅',
        type: '游戏',
        fullTime: '2021/09',
        division: [
          '剧情设计+UI+开发（负责）',
          '游戏视觉'
        ],
        more: [],
        desc: '故事发生于1949年的上海解放前夕，在场景中寻找道具、发现线索，最终解开历史的故事······',
        visit: '玩一下 play',
        link: 'https://what-177679-1306379379.tcloudbaseapp.com/pikapikakule/web-mobile/index.html',
      }, {
        time: '2020',
        title: '上海地摊经济纪录片',
        type: '视频',
        fullTime: '2020/08',
        division: [
          '统筹、摄影、编剧、剪辑（负责）',
          '配音',
          '摄影'
        ],
        more: [],
        desc: '这份视频是对暑期社会实践活动的总结，团队深入调研上海地摊经济的过去与现状，并探讨前景，希望能为人们带来对社会现象的认识与思考。',
        visit: '看一下 watch',
        link: 'https://www.bilibili.com/video/BV1av411C7K8/'
      },],
      isImgsContain: [0, 1, 0, 1, 0],
      backgrounds: [
        require('@/assets/bg/portfolio-2023.jpg'),
        require('@/assets/bg/chuan.jpg'),
        require('@/assets/bg/competition.jpg'),
        require('@/assets/bg/redsh.jpg'),
        require('@/assets/bg/ditan.jpg'),
      ],
      backgroundPreviews: [
        require('@/assets/bg/portfolio-2023_preview.mp4'),
        require('@/assets/bg/chuan_preview.mp4'),
        require('@/assets/bg/competition_preview.mp4'),
        require('@/assets/bg/redsh_preview.mp4'),
        require('@/assets/bg/ditan.jpg'),
      ],
      backgroundVideos: [
        require('@/assets/bg/portfolio-2023.mp4'),
        require('@/assets/bg/chuan.mp4'),
        require('@/assets/bg/competition.mp4'),
        require('@/assets/bg/redsh.mp4'),
        require('@/assets/bg/ditan.jpg'),
      ],
      currBgIndex: 0,
      ulIndex: 0,
      showDetails: false, // 控制是否显示详情内容
      currDetail: {
        title: '标题标题',
        type: '类型',
        fullTime: '2021/09',
        division: [
          '负责角色（我）',
          '其他工作'
        ],
        more: [],
        desc: '······'
      }, // 当前显示的详情内容
    };
  },
  computed: {
    currentBackground() {
      return this.backgrounds[this.currBgIndex];
    },
    ulStyle() {
      return {
        transform: `translateY(-${this.ulIndex * 40}px)`,
        transition: "transform 1000ms ease",
      };
    },
  },
  methods: {
    onScroll(event) {
      const scrollTop = event.target.scrollTop;
      const totalHeight = event.target.scrollHeight - event.target.clientHeight;
      const n = 5;
      const sectionHeight = totalHeight / n;
      const boundedScrollTop = Math.min(scrollTop + 50, totalHeight - 1);

      this.currBgIndex = Math.floor(boundedScrollTop / sectionHeight);
      // 滑动中就触发左边的位移，然后再根据ulIndex吸附？
      this.ulIndex = this.currBgIndex
    },
    showDetailsView(index) {
      this.currDetail = this.works[index]; // 获取点击的项目的详细内容
      this.showDetails = true; // 显示详情视图
    },
    goBack() {
      this.showDetails = false; // 返回到列表视图
    },

  },
};
</script>

<style scoped>
.full {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


.scroll-container {
  width: 100%;
  height: 100vh;
}

.home-page {
  width: 100%;
  height: calc(100vh + 800px);
  position: relative;
  display: flex;
}

.home-page .sticky {
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
}

.bg-imgs {
  overflow: hidden;
  z-index: 0;
  background: black;
  transition: all 1200ms;
}

.bg-imgs.bigger {
  transform: scale(1.1);
  transition: all 1000ms;

}

.bg-imgs .imgContain {
  object-fit: contain;
}

.bg-imgs .imgFit {
  object-fit: cover;
}

.bg-imgs img {
  opacity: 0;
  transition: opacity 600ms;
}

.bg-imgs video {
  z-index: 3;
}

.bg-imgs img.show {
  z-index: 2;
  opacity: 1;
  transition: opacity 450ms;
}

.black-cover {
  z-index: 2;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.80) 21.66%, rgba(0, 0, 0, 0.20) 100%);
  transition: all 1000ms;
}

.black-cover.indetail {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.85) 21.66%, rgba(0, 0, 0, 0.20) 100%);
}

/* 定义 fade 过渡效果的相关类 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  /* fade-enter 是进入时的初始状态，fade-leave-to 是离开时的结束状态 */
  opacity: 0;
}

.content-wrapper {
  z-index: 3;
}

.content-wrapper>a {
  display: inline-block;
  position: absolute;
  z-index: 10;
  top: 25px;
  left: 60px;
  font-size: 46px;
  text-decoration: none;
  color: #F5F5F5;
}

.content-wrapper>a span {
  display: inline-block;
  transition: all 400ms ease;
}

.content-wrapper>a:hover span {
  opacity: 0.4;
  transform: scale(0.9);
}

.content-wrapper>a .rect {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 26px);
  width: 100px;
  height: 52px;
  stroke-dashoffset: 296;
  stroke-dasharray: 296;
  transform: scale(0, 1);
  transition: stroke-dashoffset 450ms, transform 1500ms;
}

.content-wrapper>a:hover .rect {
  stroke-dashoffset: 0;
  transform: scale(1, 1);
  transition: all 800ms;
}

.content-wrapper>.intro {
  display: inline-block;
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 60px;
  font-size: 16px;
  color: #F5F5F5;
  opacity: 0.75;
  text-align: right;
}

.works-directory {
  z-index: 3;
  color: #F5F5F5;
  padding-top: max(calc(50vh - 200px), 100px);
  width: auto;
  height: 100%;
  overflow: visible;
  mask-image: linear-gradient(180deg, transparent, transparent 100px, #000 200px, #000 calc(100% - 100px), transparent);
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-weight: 400;
  margin: 20px 60px;
  font-size: 42px;
  cursor: pointer;
  transition: opacity 200ms;
}

/* li .time {
  display: inline-block;
  width: 110px;
} */

h1 sup,
li sup {
  font-size: 0.5em;
  vertical-align: text-top;
}

.details {
  z-index: 5;
  padding-top: max(calc(50vh - 230px), 125px);
  color: #F5F5F5;
  margin-left: 60px;
}

.details h1 {
  font-weight: 400;
  font-size: 42px;
}

.details .time {
  font-size: 20px;
}

.details .divisions {
  margin-top: 20px;
  display: flex;
  align-items: stretch;
  font-size: 20px;
}

.divisions .left {
  width: 11px;
  display: flex;
  align-items: center;
}

.divisions .left svg {
  width: 100%;
  height: calc(100% - 18px);
  object-fit: cover;
}

.details .divisions .right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details .more {
  margin-top: 20px;
  padding-top: 16px;
  font-size: 20px;
}

.details .desc {
  margin-top: 33px;
  width: 305px;
  font-size: 17px;
}

.details button {
  margin-top: 80px;
  background: none;
  font-size: 24px;
  color: #948C8C;
  cursor: pointer;
}

.details button:hover {
  color: #F5F5F5;
}

.details-r {
  position: absolute;
  z-index: 6;
  width: 50%;
  height: 100%;
  padding-right: 14%;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
}

.details-r a {
  margin-bottom: 60px;
  opacity: 0.6;
  display: inline-flex;
  padding: 10px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1.5px solid white;
  background: rgba(255, 255, 255, 0.25);
  color: #F5F5F5;
  font-size: 24px;
  transition: all 0.5s;
}

.details-r a:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 600px) {

  /* .content-wrapper>a {
    font-size: 36px;
  } */
  .content-wrapper>a {
    left: 30px;
  }

  .content-wrapper>.intro {
    font-size: 12px;
    left: 30px;
    top: unset;
    bottom: max(30px, calc((100vh - 450px) / 2));
    text-align: left;
  }

  li {
    margin: 20px 30px;
    font-size: 24px;
  }

  .bg-imgs .imgContain {
    object-fit: cover;
  }

  .details {
    margin-left: 30px;
    padding-right: 30px;
  }

  .details h1 {
    font-size: 24px;
    margin-top: 20px;
  }

  .details .time {
    font-size: 14px;
  }

  .details .divisions,
  .details .more {
    font-size: 16px;
  }

  .details-r {
    width: 180px;
    height: 80px;
    top: 110px;
    left: 30px;
    padding-right: 0;
    justify-content: left;
  }

  .details-r a {
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid white;
    border-radius: 3px;
    letter-spacing: -5%;
    opacity: 0.8;
  }
}
</style>
